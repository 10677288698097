export const component = async () => {
    const momentModule = await import('moment-mini');
    window.moment = momentModule.default;

    const daterangepicker = await import('./../vendor/daterangepicker');

    var dateRangeOptions = {
        showDropdowns: true,
        minYear: 2010,
        //"maxYear": parseInt(moment().format('YYYY'), 10),
        ranges: {
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'Current Month': [moment().startOf('month'), moment().endOf('month')],
            'Current Year': [moment().startOf('year'), moment().endOf('year')],
            'Previous Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        },
        locale: {
            format: 'DD/MM/YYYY',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Cancel',
            fromLabel: 'From',
            toLabel: 'To',
            customRangeLabel: 'Custom',
            weekLabel: 'W',
            daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            firstDay: 1,
        },
        parentEl: '.search-main-container',
        autoUpdateInput: false,
        linkedCalendars: false,
        showCustomRangeLabel: false
    };

    var eventRanges = {
        'Current Year': [moment().startOf('year'), moment().endOf('year')],
    };

    var eventTypeId = $('[data-sf-role="eventTypeSelectId"]').val();
    if (!(typeof eventTypeId === 'undefined')) {
        $.extend(dateRangeOptions.ranges, eventRanges);
    }

    // Restrict entry to the date field
    if ($('html').hasClass('touchevents')) {
        $('.input-date').focus(function (e) {
            $(this).blur();
        });
    }

    $('.input-date').keypress(function (e) {
        e.preventDefault();
    });

    $('.input-date').daterangepicker(dateRangeOptions);

    $('.input-date').on('apply.daterangepicker', function (ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
    });

    $('.input-date').on('cancel.daterangepicker', function (ev, picker) {
        $(this).val('');
    });
};
